import { cache, createAsync } from "@solidjs/router";
import {
  Accessor,
  For,
  Show,
  Suspense,
  createEffect,
  createMemo,
  onMount,
} from "solid-js";
import {
  Header,
  isFreshLogin,
  setIsFreshLogin,
} from "~/components/brand_l2/header";
import { CardReadySectionVoucher } from "~/components/brand_order/card_ready_section";
import { DownloadAppLarge, DownloadAppSmall } from "~/components/profile";
import { RNREvent, rnrEventManager } from "~/data/events";
import {
  getProfileRouteData,
  ProfileRouteData,
} from "~/server/data/profile_route_data";
import { getClientIdFromCookie } from "~/utils/common";
import { EmptyState } from "~/widgets/empty_state";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";
import { attachUserIdToSentry } from "~/utils/third_party/sentry";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import attachPopStateListener from "~/utils/popstate_listener";

const getData = cache(getProfileRouteData, "profile_route_data");

export default function ProfileRoute() {
  const routeData: Accessor<ProfileRouteData | undefined> = createAsync(
    () => getData(),
    {
      deferStream: true,
    }
  );

  function getMode() {
    return routeData()?.mode ?? "hubble";
  }

  onMount(() => {
    attachPopStateListener();
  });

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
    rnrEventManager.setIdentity(routeData()?.userProfile?.id ?? "");
    rnrEventManager.sendEvent(RNREvent.VISIT_PROFILE_PAGE);
    attachUserIdToSentry(routeData()?.userProfile?.id ?? "");
  });

  const isLoggedIn = createMemo<boolean>(() => {
    return isFreshLogin() ?? false;
  });

  createEffect(() => {
    setIsFreshLogin(routeData()?.isLoggedIn ?? false);
  });

  const getCoins = createMemo<number>(() => {
    return routeData()?.coinsSummary?.totalAvailable ?? 0;
  });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class="flex min-h-screen flex-col">
        <ClientOnlyComponent component={ClientComponent.ModalHost} />
        <Show when={routeData()}>
          <Header
            headerImageUrl={
              getMode() === "rnr"
                ? routeData()?.clientConfig?.clientLogoUrl
                : undefined
            }
            mode={routeData()?.mode ?? "hubble"}
            showActions={true}
            isLoggedIn={isLoggedIn}
            coins={getCoins}
            showCoins={() => true}
          />
        </Show>
        <div class="mt-5">
          <div class="flex grow flex-col px-4 lg:m-auto lg:w-[1136px] lg:flex-row lg:justify-center lg:gap-[60px] lg:px-0">
            <Show when={getMode() == "hubble"}>
              <UserDetails />
            </Show>
            <Show when={getMode() == "hubble"}>
              <div class="lg:hidden">
                <Spacer height={12} />
                <DownloadAppSmall />
                <Spacer height={32} />
              </div>
            </Show>
            <div class="w-full lg:w-[464px] ">
              <Show when={getMode() == "rnr"}>
                <UserDetails />
                <Spacer height={12} />
              </Show>
              <div class=" flex flex-row items-center  pb-1 lg:pb-2">
                <div class="text-nowrap  pr-3 text-bold text-basePrimaryDark  lg:text-h4">
                  My gift cards
                </div>
                <div class="h-[1px] w-full bg-[#DDD]"></div>
              </div>

              <Show
                when={
                  routeData()?.vouchers != null &&
                  routeData()!.vouchers.length > 0
                }
                fallback={
                  <EmptyState
                    primaryText="Gifts cards you purchase will appear here"
                    secondaryText="No gift cards purchased yet"
                  />
                }
              >
                <For each={routeData()?.vouchers}>
                  {(voucher) => (
                    <div>
                      <div class="py-2 lg:py-3">
                        <CardReadySectionVoucher
                          brandId={voucher.brandId}
                          brandName={voucher.voucherMetadata.title}
                          amount={voucher.amount}
                          tncResId={voucher.voucherMetadata.tncResId}
                          date={voucher.generatedDateTime!}
                          isAssured={
                            voucher.voucherMetadata.isVoucherAssured ?? false
                          }
                          voucherRedemptionType={
                            voucher.voucherMetadata.voucherRedemptionType
                          }
                          cardNumber={voucher.cardNumber}
                          pin={voucher.cardPin}
                          deeplink={
                            voucher.voucherMetadata.addVoucherBrandPageUrl!
                          }
                          plainLogoUrl={voucher.voucherMetadata.plainLogoUrl!}
                          cardBackgroundColor={
                            voucher.voucherMetadata.cardBackgroundColor!
                          }
                          showHubbleLogoOnCard={true}
                          shareText={voucher.voucherMetadata.shareText!}
                        />
                      </div>
                    </div>
                  )}
                </For>
              </Show>
            </div>
            <Show when={getMode() == "hubble"}>
              <div class="hidden h-min w-full lg:block lg:w-[272px]">
                <DownloadAppLarge />
              </div>
            </Show>
          </div>
        </div>
      </div>
    </Suspense>
  );

  function UserDetails() {
    return (
      <div class="w-full flex-col text-basePrimaryDark lg:w-[272px] ">
        <div>
          <span class="text-h3 lg:text-h2">
            {routeData()?.userProfile.firstName}{" "}
            {routeData()?.userProfile.lastName}
          </span>
        </div>
        <Show when={routeData()?.userProfile.email}>
          <div>
            <span class="text-normal">{routeData()?.userProfile.email}</span>
          </div>
        </Show>
        <Show when={routeData()?.userProfile.phoneNumber}>
          <div>
            <span class="text-normal">
              +91 {routeData()?.userProfile.phoneNumber}
            </span>
          </div>
        </Show>
      </div>
    );
  }
}
